import axios from 'axios';
import { fcoUrl } from './utilities';

const utTimeout = 1000;
const PACKAGE_PARTTYPE_IDS = {
    AC: '09000',
    BRAKE: '09001',
};
let tokenParam;

let isDisabled = false;
let usageTrackingData = null;
async function loadUsageTracking() {
    // no need to do anything if disabled or we already have data
    if (isDisabled || usageTrackingData) return;

    if (!loadUsageTracking._cachedRequest) {
        loadUsageTracking._cachedRequest = (async () => {
            try {
                // get disabled status and UT data here
                const { data: isDisabledResponse } = await axios.get(fcoUrl('/usageTracking/isDisabled'));

                // Need to check for specifically false since the /isDisabled endpoint isn't publicly accessible and will return undefined on unauthorized/public pages
                if (isDisabledResponse !== false) {
                    isDisabled = true;
                    return;
                }

                const { data: usageTrackingJsonResponse } = await axios.get(fcoUrl('/usageTracking/getUsageTrackingJson'));
                usageTrackingData = usageTrackingJsonResponse;

                if (!isDisabled) {
                    tokenParam = `?token=${usageTrackingData?.application?.authorization}`;
                }

                const { data: propertiesResponse } = await axios.get(fcoUrl('/usageTracking/properties'));
                usageTrackingData.paths = { ...usageTrackingData.paths, ...propertiesResponse };

                return usageTrackingData;
            } catch {
                // Fail silently and disable
                isDisabled = true;
            }
        })();
    }

    return loadUsageTracking._cachedRequest;
}

loadUsageTracking();

export function resetUsageTrackingData() {
    isDisabled = false;
    usageTrackingData = null;
    loadUsageTracking();
}

const createUsageTrackingAction =
    (fn) =>
    async (...args) => {
        await loadUsageTracking();
        if (isDisabled) return;
        return fn(...args);
    };

function requestNewUUID(ignoreSession) {
    return axios.get(fcoUrl(usageTrackingData.paths.newUuid)).then(({ data }) => {
        if (!ignoreSession) {
            sessionStorage.setItem('ut_uuid', data.uuid);
        }
        return data;
    });
}

function requestCurrentUUID() {
    return axios.get(fcoUrl(usageTrackingData.paths.existingUuid));
}

function setCurrentUUID(id) {
    usageTrackingData.uuid.uuid = id;
}

async function refreshCurrentUUID() {
    try {
        const { data } = await requestCurrentUUID();
        setCurrentUUID(data?.uuid);
    } catch {
        // fail silently
    }
}

function getCurrentDate() {
    return usageTrackingData.uuid.timeStamp;
}

function getCurrentUUID() {
    return usageTrackingData.uuid.uuid;
}

function getCustomerId() {
    return usageTrackingData.customer.id;
}

function getCustomerZipCode() {
    return usageTrackingData.customer.zipCode;
}

function getCatalogVersion() {
    return usageTrackingData.catalog.ocatVersion;
}

function getApplicationId() {
    return usageTrackingData.application.id;
}

function getStoreId() {
    return usageTrackingData.customer.storeNumber;
}

function getCatalogLookupType() {
    return usageTrackingData.catalog.lookupType;
}

function getVehicleLookupType() {
    return usageTrackingData.vehicle.lookupType;
}

function getVehicleBaseId() {
    return usageTrackingData.vehicle.baseId;
}

function getVehicleId() {
    return usageTrackingData.vehicle.id;
}

function getQuoteProducts() {
    return usageTrackingData.catalog.quoteProducts;
}

function getLookups() {
    return usageTrackingData.catalog.lookups;
}

function getVehicles(isFromQuote) {
    if (isFromQuote) {
        return getQuoteProducts().vehicles;
    }

    return getLookups().vehicles;
}

function getAddPartsToCatalogURL() {
    return fcoUrl(usageTrackingData.paths.addPartsToCatalogLookups);
}

/**
 * partTypeId values cannot be more than 5 characters, this is a catch for
 * some of the long string values that sometimes show up in the data
 * ex. part number searches show the partTypeId value as "SEARCH_RESULTS_PART_TYPE"
 * @param {number or string} partTypeId
 * @returns {number or string}
 */
function validatePartTypeId(partTypeId) {
    return partTypeId.toString().length < 6 ? partTypeId : 0;
}

function getCurrentVehicleProducts(isFromQuote) {
    const vehicle = getVehicles(isFromQuote)[getVehicleId()];

    if (!vehicle) {
        return {};
    }

    return vehicle.products || {};
}

function getVehicleProducts(vehicleId, isFromQuote) {
    if (!vehicleId) {
        return getCurrentVehicleProducts(isFromQuote);
    }

    const vehicle = getVehicles(isFromQuote)[vehicleId];

    if (!vehicle) {
        return {};
    }

    return vehicle.products || {};
}

/**
 * Gets local/store stocking and qty values for specific product
 * @param {Object} product
 * @returns {{stocking: boolean, localQOH: null or number}}
 */
function getLocalQtyDetails(product) {
    const availability = product.partPriceAvailabilityResponse;
    const details = {
        stocking: availability.stocking,
        localQOH: null,
    };

    for (let i = 0; i < availability.partAvailabilityList.length; i++) {
        if (availability.partAvailabilityList[i].locationType === 'STORE') {
            details.localQOH = availability.partAvailabilityList[i].quantityOnHand;
        }
    }

    return details;
}

/**
 * Checks a product object to see if store has stock or not
 * @param {Object} product
 * @returns {boolean}
 */
function isProductOutOfStock(product) {
    const ppAvailability = product.partPriceAvailabilityResponse;

    if (ppAvailability && ppAvailability.stocking && ppAvailability.partAvailabilityList) {
        for (let i = 0; i < ppAvailability.partAvailabilityList.length; i++) {
            if (ppAvailability.partAvailabilityList[i].locationType === 'STORE' && ppAvailability.partAvailabilityList[i].quantityOnHand > 0) {
                return false;
            }
        }
        return true;
    }

    return false;
}

function lookupParentPartType(partType) {
    return usageTrackingData.catalog.parentPartTypes[partType] ? usageTrackingData.catalog.parentPartTypes[partType] : '0';
}

function getLocalQuantityOnHand(partId, vehicleId, isFromQuote) {
    const tempVehicleId = vehicleId || getVehicleId();
    const part = getVehicleProducts(tempVehicleId, isFromQuote)[partId];

    if (part && part.localQuantityOnHand !== null) {
        return part.localQuantityOnHand;
    }

    return 0;
}

function getIsStockingItem(partId, vehicleId, isFromQuote) {
    const tempVehicleId = vehicleId || getVehicleId();
    const part = getVehicleProducts(tempVehicleId, isFromQuote)[partId];

    if (part && part.stocking !== null) {
        return part.stocking;
    }

    return false;
}

function determineKitProductByAddQuoteResponse(resp, kitData) {
    let curPrebuiltKitProduct;
    let keyType;

    for (let h = 0; h < kitData.partType.kits.length; h++) {
        keyType = resp.priceAvailabilityParamKey.productKeyType === 'LEGACY' ? 'legacyKey' : 'catalogKey';

        for (let k = 0; k < kitData.partType.kits[h].completeProducts.length; k++) {
            if (
                resp.priceAvailabilityParamKey.itemId === kitData.partType.kits[h].completeProducts[k][keyType].itemId &&
                resp.priceAvailabilityParamKey.groupId === kitData.partType.kits[h].completeProducts[k][keyType].groupId
            ) {
                curPrebuiltKitProduct = kitData.partType.kits[h].completeProducts[k];
                break;
            }
        }
    }

    return curPrebuiltKitProduct;
}

function assembleLookupSummary(summaryData, uuid) {
    const lookUpSummaryData = [];
    let vehicleId;
    let vehicleLookupType;

    for (let i = 0; i < summaryData.length; i++) {
        if (summaryData[i].vehicleId !== null) {
            if (typeof summaryData[i].vehicleId === 'undefined') {
                vehicleId = getVehicleBaseId();
            } else {
                ({ vehicleId } = summaryData[i]);
            }
        }

        if (summaryData[i].vehicleLookupType !== null) {
            if (typeof summaryData[i].vehicleLookupType === 'undefined') {
                vehicleLookupType = getVehicleLookupType();
            } else {
                ({ vehicleLookupType } = summaryData[i]);
            }
        } else {
            vehicleLookupType = 'NULL';
        }

        lookUpSummaryData.push({
            key: {
                date: getCurrentDate(),
                application: getApplicationId(),
                partTypeId: summaryData[i].partTypeId,
                uuid: uuid || getCurrentUUID(),
                servicingStoreNumber: getStoreId(),
            },
            parentPartTypeId: lookupParentPartType(summaryData[i].partTypeId),
            catalogLookupType: summaryData[i].catalogLookupType || getCatalogLookupType(),
            vehicleLookupType,
            vehicleId,
            customerId: getCustomerId(),
            customerZipCode: getCustomerZipCode(),
            catalogVersion: getCatalogVersion(),
        });
    }

    return lookUpSummaryData;
}

async function logLookupSummary(lookUpSummaryData) {
    await axios({
        method: 'post',
        data: JSON.stringify(lookUpSummaryData),
        timeout: utTimeout,
        url: usageTrackingData.paths.lookupSummaryPath + tokenParam,
    }).catch(() => {
        // TODO: will need to add to failure queue in future phase
    });
}

/**
 * Returns result summary lookup object that describes part types that were returned in lookup process
 * @param {string} catalogLookupType - optional overriding parameter for specifying a different lookup type
 * @returns {Array}
 */
function getLookupSummaryData(catalogLookupType) {
    const data = usageTrackingData.catalog.selectedPartTypes;
    const results = [];
    let tempObj;
    let parentPartTypeId;

    if (usageTrackingData.catalog.lookupType === 'PART_NO') {
        results.push({
            partTypeId: 0,
            parentPartTypeId: 0,
        });
    } else if (catalogLookupType && catalogLookupType === 'REL_ITEM') {
        results.push({
            partTypeId: 0,
            parentPartTypeId: 0,
            vehicleId: 0,
            vehicleLookupType: null,
            catalogLookupType,
        });
    } else {
        for (let i = 0; i < data.length; i++) {
            parentPartTypeId = lookupParentPartType(data[i].partTypeId);
            tempObj = {
                partTypeId: validatePartTypeId(data[i].partTypeId),
                parentPartTypeId,
            };

            if (catalogLookupType) {
                tempObj.catalogLookupType = catalogLookupType;
            }

            results.push(tempObj);
        }
    }

    return results;
}

function getAttributeData(attribute) {
    let attrID;

    if (attribute.attributeType === 'vehicle') {
        attrID = 'Application';
    } else if (attribute.attributeType === 'product') {
        attrID = 'Product';
    }

    return {
        attributeTypeID: attrID,
        attributeID: attribute.attributeID,
        attributeValueID: attribute.attributeValueID,
    };
}

/**
 * Gets specific lookup details that describe how product was looked up
 * @param {boolean} isRelatedProduct - true if lookup details are being gathered for related product actions/lookups
 * @param {Array} filteredBrands - contains list of currently filtered brands
 * @returns {Array} lookup details objects with required data to be logged
 */
function getLookupDetailsData(isRelatedProduct = false, filteredBrands = []) {
    const results = [];
    const typeObj = {
        detailType: 'TYPE',
    };
    const data = usageTrackingData.catalog.selectedPartTypes;
    let i;

    if (usageTrackingData.vehicle.lookupType === 'YMM') {
        typeObj.detailValue = 'app';
    } else if (usageTrackingData.vehicle.lookupType === 'NULL') {
        typeObj.detailValue = 'all';
    } else if (usageTrackingData.vehicle.lookupType === 'VIN') {
        typeObj.detailValue = 'vin';
    }

    if (usageTrackingData.catalog.lookupType === 'PROMO') {
        results.push({
            detailType: 'KIT',
            detailValue: 'kit',
        });
    }

    // interchange overrides lookup type for this value
    if (usageTrackingData.catalog.lookupType === 'INTCHANGE') {
        typeObj.detailValue = 'ic';

        results.push({
            detailType: 'PART_NO',
            detailValue: usageTrackingData.catalog.searchQuery,
        });

        for (i = 0; i < data.length; i++) {
            results.push({
                detailType: 'SELECTED_PART_TYPE',
                detailValue: data[i].partTypeId,
            });

            results.push({
                detailType: 'PART_NAME',
                detailValue: data[i].partTypeName,
            });
        }

        if (usageTrackingData.catalog.selectedManufacturer) {
            results.push({
                detailType: 'MANUFACTURER',
                detailValue: usageTrackingData.catalog.selectedManufacturer,
            });

            // Supposedly this is always to be hard coded to this value for RWD
            // when selecting a mfg during interchange search
            results.push({
                detailType: 'LINES',
                detailValue: 'All Lines',
            });
        }
    }

    if (isRelatedProduct) {
        typeObj.detailValue = 'all';
    }

    // BRD says to always have at least 1 lookup detail with detailType being "TYPE"
    results.push(typeObj);

    if (!isRelatedProduct) {
        if (usageTrackingData.catalog.lookupType === 'PART_NO') {
            results.push({
                detailType: 'PART_NO',
                detailValue: usageTrackingData.catalog.searchQuery,
                custom: 'Y',
            });
        } else if (usageTrackingData.catalog.lookupType === 'DRILL_DOWN' || usageTrackingData.catalog.lookupType === 'JOB') {
            for (i = 0; i < data.length; i++) {
                results.push({
                    detailType: 'SELECTED_PART_TYPE',
                    detailValue: data[i].partTypeId,
                    custom: 'Y',
                });

                results.push({
                    detailType: 'PART_NAME',
                    detailValue: data[i].partTypeName || '',
                    custom: 'Y',
                });

                // check to see if user searched a term that resulted in having filteredBrands
                if (filteredBrands.length > 0 && usageTrackingData.catalog.searchQuery !== '') {
                    results.push({
                        detailType: 'MANUFACTURER',
                        detailValue: usageTrackingData.catalog.searchQuery,
                        custom: 'Y',
                    });
                }
            }
        }
    }

    return results;
}

/**
 * Gets data that summarizes stocking information for each part type looked up
 * @param {Array} productList - contains part type objects that each contain product data for that part type
 * @returns {Array} contains result summary objects for each part type
 */
function getResultSummaryData(productList) {
    const resultSummaryArray = [];
    let countStocking;
    let countNonStocking;
    let countSpecialOrder;
    let countOutOfStock;
    let countDisplayed;
    let availability;
    const newPl = [];

    // kits results do not have same structure as regular product results, forced to map data to most common structure
    productList.forEach((partType) => {
        if (partType.kits) {
            partType.kits.forEach((kit) => {
                kit.completeProducts.forEach((product) => {
                    const partTypeIndex = newPl.findIndex(({ partTypeId }) => partTypeId === product.productOriginDetails);

                    if (partTypeIndex !== -1) {
                        newPl[partTypeIndex].completeProducts.push(product);
                    } else {
                        newPl.push({
                            partTypeId: product.productOriginDetails,
                            completeProducts: [product],
                        });
                    }
                });
            });

            // kits results can sometimes have duplicate products so we want to remove duplicates
            newPl.forEach((part) => {
                part.completeProducts = part.completeProducts.filter(
                    (result, index, self) =>
                        index === self.findIndex((obj) => obj.catalogKey.formattedProductKey === result.catalogKey.formattedProductKey)
                );
            });
        } else {
            newPl.push({
                partTypeId: partType.partTypeId,
                completeProducts: partType.completeProducts,
            });
        }
    });

    for (let plIndex = 0; plIndex < newPl.length; plIndex++) {
        countStocking = 0;
        countNonStocking = 0;
        countSpecialOrder = 0;
        countOutOfStock = 0;
        countDisplayed = -1; // BRD says to hard code this value to -1

        // AC and Brake packages are not to be displayed so we also won't track them.
        // We can only remove this if someday they are removed from the catalog.
        if (newPl[plIndex].partTypeId !== PACKAGE_PARTTYPE_IDS.AC && newPl[plIndex].partTypeId !== PACKAGE_PARTTYPE_IDS.BRAKE) {
            for (let productIndex = 0; productIndex < newPl[plIndex].completeProducts.length; productIndex++) {
                availability = newPl[plIndex].completeProducts[productIndex].partPriceAvailabilityResponse;

                if (!availability.stocking && availability.itemMaster) {
                    countNonStocking += 1;
                }

                if (!availability.itemMaster) {
                    countSpecialOrder += 1;
                }

                if (availability.stocking) {
                    countStocking += 1;

                    if (isProductOutOfStock(newPl[plIndex].completeProducts[productIndex])) {
                        countOutOfStock += 1;
                    }
                }
            }

            resultSummaryArray.push({
                partTypeId: validatePartTypeId(newPl[plIndex].partTypeId),
                countProducts: newPl[plIndex].completeProducts.length,
                countStocking,
                countNonStocking,
                countSpecialOrder,
                countOutOfStock,
                countDisplayed,
                duration: 0,
            });
        }
    }

    return resultSummaryArray;
}

/**
 * This is used for product lookups, add to quote (excluding quick adds),
 * and product view details (excluding this action on quote page).
 * This is similar to buildResultsDetailsObj() however they are accepting data in
 * slightly different formats. Could possibly be refactored in the future to combine them.
 * @param {number} qty - desired product quantity customer chose
 * @param {string} productState - defines the action such as: "ADD_TO_INVOICE", "VW_DETAILS", "REM_FROM_INVOICE"
 * @param {Object} partData - product data that is slightly different than productList data
 * @param {number} partTypeId - alternate part type ID if we want to override productList partTypeId value
 * @returns {Object} result details object containing all properties/values required for logging
 */
function getResultDetailsObj(qty, productState, partData, partTypeId) {
    let localQOH = null;
    let hubQOH = -1;
    let dcQOH = -1;
    let curQOH;

    const partsAvailable = partData.partPriceAvailabilityResponse.partAvailabilityList;
    const validatedPartTypeId = validatePartTypeId(partTypeId);

    for (let k = 0; k < partsAvailable.length; k++) {
        curQOH = partsAvailable[k].quantityOnHand;
        curQOH = curQOH >= 0 ? curQOH : 0;
        if (partsAvailable[k].locationType === 'STORE') {
            localQOH = curQOH;
        } else if (partsAvailable[k].locationType === 'HUB') {
            hubQOH = curQOH;
        } else if (partsAvailable[k].locationType === 'DISTRIBUTION_CENTER') {
            dcQOH = curQOH;
        }
    }

    return {
        partTypeId: validatedPartTypeId,
        line: partData.legacyKey.groupId,
        item: partData.legacyKey.itemId,
        catalogItemId: partData.catalogKey.itemId,
        fitmentNotes: '', // BRD says always leave this blank
        productState,
        quotedPrice: partData.itemCost,
        desiredQuantity: qty,
        localQOH,
        hubQOH,
        dcQOH,
        onOrder: partData.partPriceAvailabilityResponse.onOrder,
        onBackOrder: partData.partPriceAvailabilityResponse.onBackOrder,
        stocking: partData.partPriceAvailabilityResponse.stocking,
        brandCode: partData.catalogKey.groupId,
    };
}

function getResultDetailsData(productList, productState, qty) {
    let resultDetails = [];
    let quantity;
    let plIdx;
    let plKitIdx;
    let plProductIdx;

    for (plIdx = 0; plIdx < productList.length; plIdx++) {
        if (productList[plIdx].kits) {
            for (plKitIdx = 0; plKitIdx < productList[plIdx].kits.length; plKitIdx++) {
                for (plProductIdx = 0; plProductIdx < productList[plIdx].kits[plKitIdx].completeProducts.length; plProductIdx++) {
                    if (productList[plIdx].kits[plKitIdx].completeProducts[plProductIdx].partPriceAvailabilityResponse.itemMaster) {
                        quantity = qty || -1;
                        resultDetails.push(
                            getResultDetailsObj(
                                quantity,
                                productState,
                                productList[plIdx].kits[plKitIdx].completeProducts[plProductIdx],
                                productList[plIdx].kits[plKitIdx].completeProducts[plProductIdx].productOriginDetails
                            )
                        );
                    }
                }
            }

            // kits results can sometimes have duplicate products, remove duplicates as requested in RWD-3776
            resultDetails = resultDetails.filter(
                (result, index, self) => index === self.findIndex((obj) => obj.line === result.line && obj.item === result.item)
            );
        } else {
            for (plProductIdx = 0; plProductIdx < productList[plIdx].completeProducts.length; plProductIdx++) {
                if (productList[plIdx].completeProducts[plProductIdx].partPriceAvailabilityResponse.itemMaster) {
                    quantity = qty || -1;

                    resultDetails.push(
                        getResultDetailsObj(quantity, productState, productList[plIdx].completeProducts[plProductIdx], productList[plIdx].partTypeId)
                    );
                }
            }
        }
    }

    return resultDetails;
}

function buildResultsDetailsObj(part, productState, setLocalAvailability) {
    const ppAvailability = part.partPriceAvailabilityResponse || {};
    const availabilityList = ppAvailability.partAvailabilityList || [];
    const brandCode = !part.catalogKey ? null : part.catalogKey.groupId;
    const catalogItemId = !part.catalogKey ? null : part.catalogKey.itemId;
    let hubQOH = -1;
    let dcQOH = -1;
    let localQOH = null;
    let stocking = null;
    let curQOH;
    let partTypeId;

    if (part.partTypeId) {
        ({ partTypeId } = part);
    } else if (part.itemOrigin === 'CATALOG') {
        partTypeId = part.itemOriginDetails;
    } else {
        partTypeId = 0;
    }

    for (let i = 0; i < availabilityList.length; i++) {
        curQOH = availabilityList[i].quantityOnHand;
        if (curQOH > -1) {
            if (availabilityList[i].locationType === 'STORE') {
                if (setLocalAvailability && localQOH === null) {
                    localQOH = curQOH;
                }
            } else if (availabilityList[i].locationType === 'HUB') {
                if (hubQOH === -1) {
                    hubQOH = curQOH;
                }
            } else if (availabilityList[i].locationType === 'DISTRIBUTION_CENTER') {
                if (dcQOH === -1) {
                    dcQOH = curQOH;
                }
            }
        }
    }

    if (setLocalAvailability) {
        ({ stocking } = ppAvailability);
    }

    return {
        brandCode,
        dcQOH,
        desiredQuantity: part.itemQuantity || part.quantity,
        hubQOH,
        line: part.line || part.legacyKey.groupId,
        item: part.itemNumber || part.legacyKey.itemId,
        catalogItemId,
        localQOH,
        onBackOrder: ppAvailability.onBackOrder,
        onOrder: ppAvailability.onOrder,
        partTypeId: validatePartTypeId(partTypeId),
        productState,
        quotedPrice: part.itemCost,
        stocking,
    };
}

function assembleLookupDetails(detailsData, uuid) {
    const lookUpDetailsData = [];

    for (let i = 0; i < detailsData.length; i++) {
        lookUpDetailsData.push({
            key: {
                uuid: uuid || getCurrentUUID(),
                detailType: detailsData[i].detailType,
                servicingStoreNumber: getStoreId(),
            },
            detailValue: detailsData[i].detailValue,
            custom: 'Y',
        });
    }
    return lookUpDetailsData;
}

async function logLookupDetails(lookupDetailsData) {
    await axios({
        method: 'post',
        data: JSON.stringify(lookupDetailsData),
        timeout: utTimeout,
        url: usageTrackingData.paths.lookupDetailsPath + tokenParam,
    }).catch(() => {
        // TODO: will need to add to failure queue in future phase
    });
}

function assembleLookupAttributes(attributeData, uuid) {
    const lookUpAttributeData = [];

    for (let i = 0; i < attributeData.length; i++) {
        lookUpAttributeData.push({
            key: {
                attributeTypeId: attributeData[i].attributeTypeID,
                attributeId: attributeData[i].attributeID,
                attributeValueId: attributeData[i].attributeValueID,
                uuid: uuid || getCurrentUUID(),
                servicingStoreNumber: getStoreId(),
            },
            attributeState: 'ANSWERED',
            partTypeId: null,
        });
    }

    return lookUpAttributeData;
}

async function logLookupAttributes(lookUpAttributesData) {
    await axios({
        method: 'post',
        data: JSON.stringify(lookUpAttributesData),
        timeout: utTimeout,
        url: usageTrackingData.paths.lookupAttributesPath + tokenParam,
    }).catch(() => {
        // TODO: will need to add to failure queue in future phase
    });
}

function assembleResultsSummary(data, uuid) {
    const results = [];

    for (let i = 0; i < data.length; i++) {
        results.push({
            key: {
                uuid: uuid || getCurrentUUID(),
                servicingStoreNumber: getStoreId(),
                date: getCurrentDate(),
                partTypeId: data[i].partTypeId,
            },
            productsReturned: data[i].countProducts,
            stockingProductsReturned: data[i].countStocking,
            nonStockProductsReturned: data[i].countNonStocking,
            specialOrderProductsReturned: data[i].countSpecialOrder,
            outOfStockProductsReturned: data[i].countOutOfStock,
            countDisplayed: data[i].countDisplayed,
            duration: 0,
        });
    }

    return results;
}

async function logResultsSummary(resultsSummary) {
    await axios({
        method: 'post',
        data: JSON.stringify(resultsSummary),
        timeout: utTimeout,
        url: usageTrackingData.paths.resultsSummaryPath + tokenParam,
    }).catch(() => {
        // TODO: will need to add to failure queue in future phase
    });
}

function assembleResultsDetails(data, isActionItem, uuid, vehicleId, isFromQuote) {
    const results = [];
    let uuidProducts;
    let uuidProductKey;
    let finalUUID;
    let partId;
    let isStockingItem;

    if (uuid) {
        finalUUID = uuid;
    } else if (isActionItem && data && data.length > 0) {
        uuidProducts = getVehicleProducts(vehicleId, isFromQuote);
        uuidProductKey = `${data[0].brandCode}|${data[0].catalogItemId}`;
        if (uuidProducts && uuidProducts[uuidProductKey]) {
            finalUUID = uuidProducts[uuidProductKey].uuid.uuid;
        }
    }

    if (!finalUUID) {
        finalUUID = getCurrentUUID();
    }

    for (let i = 0; i < data.length; i++) {
        partId = `${data[i].brandCode}|${data[i].catalogItemId}`;
        isStockingItem = data[i].stocking;
        if (isStockingItem === null || typeof isStockingItem === 'undefined') {
            isStockingItem = getIsStockingItem(partId, vehicleId, isFromQuote);
        }
        results.push({
            key: {
                uuid: finalUUID,
                servicingStoreNumber: getStoreId(),
                partTypeId: data[i].partTypeId,
                line: data[i].line,
                item: data[i].item,
                productState: data[i].productState,
            },
            date: getCurrentDate(),
            fitmentNotes: '', // BRD says to always leave blank/empty string
            quotedPrice: data[i].quotedPrice,
            desiredQuantity: data[i].desiredQuantity,
            localQOH: data[i].localQOH || getLocalQuantityOnHand(partId, vehicleId, isFromQuote),
            hubQOH: data[i].hubQOH,
            dcQOH: data[i].dcQOH,
            stockingItem: isStockingItem,
            onOrder: data[i].onOrder,
            onBackOrder: data[i].onBackOrder,
            brandCode: data[i].brandCode,
        });
    }

    return results;
}

async function logResultsDetails(resultsDetails) {
    await axios({
        method: 'post',
        data: JSON.stringify(resultsDetails),
        timeout: utTimeout,
        url: usageTrackingData.paths.resultsDetailsPath + tokenParam,
    }).catch(() => {
        // TODO: will need to add to failure queue in future phase
    });
}

async function addPartsToQuoteProducts(info) {
    await axios({
        method: 'post',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        data: JSON.stringify(info),
        url: fcoUrl(usageTrackingData.paths.addPartsToQuote),
    })
        .then(({ data }) => {
            // update global usage tracking details so future logging has up to date data
            usageTrackingData.catalog.quoteProducts.vehicles = data.vehicles;
        })
        .catch(() => {
            // TODO: will need to add to failure queue in future phase
        });
}

async function removePartsFromQuoteProducts(data) {
    await axios({
        method: 'post',
        contentType: 'application/json; charset=utf-8',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        data: JSON.stringify(data),
        url: fcoUrl(usageTrackingData.paths.removePartsFromQuote),
    }).catch(() => {
        // TODO: will need to add to failure queue in future phase
    });
}

function overwriteUsageTrackingData(utData) {
    const { paths } = usageTrackingData;
    usageTrackingData = utData;
    usageTrackingData.paths = paths;
}

function createCatalogLookup({ partTypes, attributes = [], isRelatedProduct = false, filteredBrands = [] } = {}) {
    const catalogLookupObj = {
        lookupSummary: getLookupSummaryData(isRelatedProduct ? 'REL_ITEM' : undefined),
        lookupDetails: getLookupDetailsData(isRelatedProduct, filteredBrands),
        lookupAttributes: attributes.map((attribute) =>
            getAttributeData({
                attributeType: attribute.attributeType,
                attributeID: attribute.attributeId,
                attributeValueID: attribute.valueId,
            })
        ),
    };

    if (partTypes) {
        catalogLookupObj.resultsSummary = getResultSummaryData(partTypes);
        catalogLookupObj.resultsDetails = getResultDetailsData(partTypes, 'LOOKUP');
    }

    return catalogLookupObj;
}

async function logCatalogLookup(data, optionalUUID) {
    if (!isDisabled) {
        const utCalls = [];
        const finalUUID = optionalUUID || getCurrentUUID();

        if (!optionalUUID) {
            const lastTrackedLookupUUID = sessionStorage.getItem('ut_uuid');
            const isAlreadyTracked = !!lastTrackedLookupUUID && lastTrackedLookupUUID === finalUUID;

            sessionStorage.setItem('ut_uuid', finalUUID);

            // If we're using the default UUID and we've already tracked that lookup, just bail.
            if (isAlreadyTracked) {
                return;
            }
        }

        if (data?.lookupDetails?.length > 0) {
            utCalls.push(logLookupDetails(assembleLookupDetails(data.lookupDetails, optionalUUID)));
        }

        if (data?.lookupSummary?.length > 0) {
            utCalls.push(logLookupSummary(assembleLookupSummary(data.lookupSummary, optionalUUID)));
        }

        if (data?.resultsSummary?.length > 0) {
            utCalls.push(logResultsSummary(assembleResultsSummary(data.resultsSummary, optionalUUID)));
        }

        if (data?.resultsDetails?.length > 0) {
            utCalls.push(logResultsDetails(assembleResultsDetails(data.resultsDetails, false, optionalUUID)));
        }

        if (data?.lookupAttributes?.length > 0) {
            utCalls.push(logLookupAttributes(assembleLookupAttributes(data.lookupAttributes, optionalUUID)));
        }
        await Promise.all(utCalls).then(() => {
            axios({
                method: 'post',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
                url: fcoUrl(usageTrackingData.paths.completeCatalogLookupEvent),
                data: JSON.stringify({ uuid: finalUUID }),
            }).catch(() => {
                // nothing for now
            });
        });
    }
    /* User performs a catalog lookup (Most Popular or All Part Types tabs) and views PLP for part type(s) selected.
        - LookupSummary (one per part type)X
        - LookupAttribute (if applicable)X
        - LookupDetails (log part types and vehicle selected, if applicable) X
        - ResultsSummary (one per part type returned)
        - ResultsDetails (one per IMASTER part returned)
        * */
}

// this is the function we make available to our app for logging catalog lookups
// other internal UT service functions can either call this function or logCatalogLookup() directly (depending on the scenario)
const catalogLookup = createUsageTrackingAction(async (catalogLookupData, optionalUUID) => {
    await refreshCurrentUUID();
    return logCatalogLookup(createCatalogLookup(catalogLookupData), optionalUUID);
});

async function logAddPartResultsDetails(addProductResultsDetails, optionalUUID) {
    if (isDisabled) return;

    const usageTrackingProduct = getCurrentVehicleProducts()[`${addProductResultsDetails.brandCode}|${addProductResultsDetails.catalogItemId}`];

    if (!usageTrackingProduct) return;

    const quoteProduct = {
        ...usageTrackingProduct,
        vehicleId: getVehicleId(),
        baseVehicleId: getVehicleBaseId(),
    };

    await addPartsToQuoteProducts([quoteProduct]);
    await logResultsDetails(assembleResultsDetails([addProductResultsDetails], true, optionalUUID));

    /* User adds part to the Quote (from the PLP or PDP).
        - ResultsDetails ("addToInvc" type)
        * */
}

const relatedPartTypesLookup = createUsageTrackingAction(async (partTypes, vehicle) => {
    try {
        const uuid = await requestNewUUID();
        const newProducts = partTypes.flatMap(({ partTypeId, parentPartTypeId, completeProducts }) =>
            completeProducts.map((product) => {
                const { stocking, localQOH } = getLocalQtyDetails(product);

                return {
                    vehicleId: vehicle.id,
                    baseVehicleId: vehicle.vehicleId,
                    partId: product.catalogKey.formattedProductKey,
                    /**
                     * Need to hard code this to DRILL_DOWN because choosing a new related part type from
                     * related parts drawer is technically a drill down. We need this value to update correctly
                     * in case a user started with a PART_NO (search) lookup so global usage tracking property gets updated
                     * and future logging of related part types still logs things correctly without getting hung up on PART_NO checks
                     */
                    lookupType: 'DRILL_DOWN',
                    partTypeId,
                    parentPartTypeId,
                    uuid,
                    stocking,
                    localQuantityOnHand: localQOH,
                };
            })
        );
        const { data: usageTrackingDataResponse } = await axios.post(getAddPartsToCatalogURL(), newProducts);

        overwriteUsageTrackingData(usageTrackingDataResponse);
        await catalogLookup(createCatalogLookup({ partTypes }), uuid.uuid);
    } catch {
        // do nothing for now
    }
});

const relatedProductsLookup = createUsageTrackingAction(async (lookupResults) => {
    try {
        const vehicleId = getVehicleId();
        const baseVehicleId = getVehicleBaseId();
        const data = {
            ...lookupResults,

            // set the unknown defaults for these, as these related products won't have this information
            partTypeId: 0,
            parentPartTypeId: null,
        };

        const uuid = await requestNewUUID();
        const newProducts = data.completeProducts.map((product) => {
            const { stocking, localQOH } = getLocalQtyDetails(product);

            return {
                vehicleId,
                baseVehicleId,
                partId: product.catalogKey.formattedProductKey,
                lookupType: getCatalogLookupType(),
                parentPartTypeId: null,
                uuid,
                stocking,
                localQuantityOnHand: localQOH,
            };
        });

        const { data: usageTrackingOverwrite } = await axios.post(getAddPartsToCatalogURL(), newProducts);

        overwriteUsageTrackingData(usageTrackingOverwrite);

        sessionStorage.setItem(
            'curRelatedPartLookup',
            JSON.stringify({
                lookupData: createCatalogLookup({ partTypes: [data], isRelatedProduct: true }),
                uuid: uuid.uuid,
                hasBeenLogged: false,
            })
        );
    } catch {
        // do nothing for now
    }
});

const startCatalogLookup = createUsageTrackingAction(async ({ resetUUID = true, clearSelectedPartTypes = true, lookupType = 'RELEVANT' } = {}) => {
    try {
        return axios.post(fcoUrl('/usageTracking/startCatalogLookup'), { resetUUID, clearSelectedPartTypes, lookupType });
    } catch {
        // Fail silently
    }
});

const addPartToQuote = createUsageTrackingAction(async (addedProductData, { onOrder, onBackOrder }) => {
    const { brandCode, itemCost, itemNumber, itemOrigin, itemOriginDetails, itemQuantity, line, locations, partNumber } = addedProductData;
    const isRelatedPart = itemOrigin === 'CROSS_SELL_PR_PRODUCT';
    const curRelatedProductLookupData = isRelatedPart ? JSON.parse(sessionStorage.getItem('curRelatedPartLookup')) : {};
    let hubQOH = -1;
    let dcQOH = -1;
    let uuid;

    locations.forEach(({ quantityOnHand, locationType }) => {
        if (locationType === 'HUB') {
            hubQOH = quantityOnHand;
        } else if (locationType === 'DISTRIBUTION_CENTER') {
            dcQOH = quantityOnHand;
        }
    });

    const resultDetails = {
        partTypeId: validatePartTypeId(itemOriginDetails),
        line,
        item: itemNumber,
        catalogItemId: partNumber,
        fitmentNotes: '', // BRD says always leave this blank
        productState: 'ADD_TO_INVOICE',
        quotedPrice: itemCost,
        desiredQuantity: itemQuantity,
        hubQOH,
        dcQOH,
        onOrder,
        onBackOrder,
        brandCode,
    };

    if (isRelatedPart && !curRelatedProductLookupData.hasBeenLogged) {
        ({ uuid } = curRelatedProductLookupData);
        await catalogLookup(curRelatedProductLookupData.lookupData, uuid);
        curRelatedProductLookupData.hasBeenLogged = true;
        sessionStorage.setItem('curRelatedPartLookup', JSON.stringify(curRelatedProductLookupData));
    }

    try {
        await logAddPartResultsDetails(resultDetails, uuid);
    } catch {
        // Some error handling
    }
});

const addKitPartToQuote = createUsageTrackingAction(async (addedProductData, kitData) => {
    // this is specific to when we add kit wizard products to quote, it uses a mix of response data and data sent to add to quote function.
    let hubQOH = -1;
    let dcQOH = -1;
    let curLoc;
    let curQOH;

    for (let i = 0; i < addedProductData.locations.length; i++) {
        curLoc = addedProductData.locations[i];
        curQOH = curLoc.quantityOnHand >= 0 ? curLoc.quantityOnHand : 0;
        if (curLoc.locationType === 'HUB') {
            hubQOH = curQOH;
        } else if (curLoc.locationType === 'DISTRIBUTION_CENTER') {
            dcQOH = curQOH;
        }
    }

    const resultDetails = {
        partTypeId: kitData.partTypeId || addedProductData.itemOriginDetails,
        line: addedProductData.line,
        item: kitData.legacyKey.itemId,
        catalogItemId: kitData.catalogKey.itemId,
        fitmentNotes: '', // BRD says always leave this blank
        productState: 'ADD_TO_INVOICE',
        quotedPrice: kitData.itemCost,
        desiredQuantity: kitData.userSpecifiedQuantity,
        hubQOH,
        dcQOH,
        onOrder: kitData.partPriceAvailabilityResponse.onOrder,
        onBackOrder: kitData.partPriceAvailabilityResponse.onBackOrder,
        brandCode: addedProductData.brandCode,
    };

    try {
        await logAddPartResultsDetails(resultDetails);
    } catch {
        // Fail silently
    }
});

export const addPrebuiltKitToQuote = createUsageTrackingAction(async (addedProducts, kit, partTypeId) => {
    try {
        const { selectedPartTypes } = usageTrackingData.catalog;
        let parentPartTypeId;

        // first log new lookup summary for this part type id
        if (selectedPartTypes.length > 0) {
            // if selected part types is populated when looking up kits it is safe to select
            // one and only slot as users can only select one catalog lookup type when choosing kits
            parentPartTypeId = selectedPartTypes[0].partTypeId;
        } else {
            parentPartTypeId = 0;
        }

        await logLookupSummary(
            assembleLookupSummary([
                {
                    partTypeId,
                    parentPartTypeId,
                },
            ])
        );

        addedProducts.forEach((addedProduct) => {
            // create specific object for usage tracking to consume
            const kitData = {
                partType: {
                    kits: [kit],
                },
            };

            // first determine which kitWizardProducts product matches our current response product
            const curPrebuiltKitProduct = determineKitProductByAddQuoteResponse(addedProduct, kitData);

            // should always have a product match but put this here just in case to prevent any js errors
            if (curPrebuiltKitProduct) {
                addKitPartToQuote(addedProduct, curPrebuiltKitProduct);
            }
        });
    } catch (ex) {
        /*
        Ignore usage tracking errors for now, invalid usage tracking records are automatically
        scrubbed at the end of the day
        */
    }
});

const plpMoreInformation = createUsageTrackingAction(async (qty, productState, partData, partTypeId, optionalUUID) => {
    const data = getResultDetailsObj(qty, productState, partData, partTypeId);
    logResultsDetails(assembleResultsDetails(data, true, optionalUUID)).catch(() => {
        // Fail silently
    });
    /* On the PLP, user clicks on a part image, part name, or the "More Information" link.
        - ResultsDetails ("vwDtl" type)
        * */
});

const removePartFromQuote = createUsageTrackingAction(async (data, groupId, itemId) => {
    /* User removes part from Quote (from the Quote page or Mini-Quote).
    - ResultsDetails ("rmvFromInvc" type)
    * */
    const resp = await axios.get(fcoUrl(`/product/extraInfo?mfg=${groupId}&partNumber=${itemId}`));

    data.product.catalogKey = { itemId, groupId };
    data.product.legacyKey = resp.data;

    const resultsDetails = buildResultsDetailsObj(data.product, 'REM_FROM_INVOICE');

    await Promise.all([
        logResultsDetails(assembleResultsDetails([resultsDetails], true, null, data.vehicleId, true)),
        removePartsFromQuoteProducts([
            {
                vehicleId: data.vehicleId,
                partId: `${resultsDetails.brandCode}|${resultsDetails.catalogItemId}`,
            },
        ]),
    ]).catch(() => {
        // fail silently
    });
});

const viewPartFromQuote = createUsageTrackingAction(async (data, line, itemNumber) => {
    const getCatalogKeyURL = fcoUrl(`/product/extraInfo?&sourceType=LEGACY&targetType=CATALOG&mfg=${line}&partNumber=${itemNumber}`);
    const { data: catalogKey } = await axios.get(getCatalogKeyURL);

    data.product.catalogKey = catalogKey;

    const resultsDetails = assembleResultsDetails([buildResultsDetailsObj(data.product, 'VW_DETAILS')], true, null, data.vehicleId, true);
    await logResultsDetails(resultsDetails).catch(() => {
        // Fail silently
    });
});

const quickAddPartsToQuote = createUsageTrackingAction(async ({ products, isWorksheet, vehicleId }) => {
    /* User selects parts on the Quick Order box, then clicks Go To Quote.
    - LookupSummary (one record)
    - LookupDetails (log part numbers entered)
    - ResultsSummary (one record with part type = 0)
    - ResultsDetails ("lookup" records for all IMASTER parts. "addToInvc" records for all parts.)
    * */
    await axios.get(fcoUrl(usageTrackingData.paths.disposableUuid)).then((uuid) => {
        const lookupType = isWorksheet ? 'QUICK_ADD' : 'PART_NO';
        const utObj = {
            lookupSummary: [
                {
                    partTypeId: 0,
                    parentPartTypeId: 0,
                    catalogLookupType: lookupType,
                    vehicleLookupType: null,
                    vehicleId: null,
                },
            ],
            lookupDetails: [
                {
                    detailType: 'TYPE',
                    detailValue: 'all',
                },
            ],
            resultsSummary: [],
            resultsDetails: [],
            quoteProducts: [],
        };
        const stockingTotals = {
            countStocking: 0,
            countNonStocking: 0,
            countSpecialOrder: 0,
            countOutOfStock: 0,
        };
        let ppAvailability;
        let resultsDetailsAddToInvoice;

        for (let partIndex = 0; partIndex < products.length; partIndex++) {
            ppAvailability = products[partIndex].partPriceAvailabilityResponse;
            if (ppAvailability.stocking) {
                stockingTotals.countStocking += 1;
            }
            if (isProductOutOfStock(products[partIndex])) {
                stockingTotals.countOutOfStock += 1;
            }
            if (ppAvailability.nonStocking && ppAvailability.itemMaster) {
                stockingTotals.countNonStocking += 1;
            }
            if (!ppAvailability.itemMaster) {
                stockingTotals.countSpecialOrder += 1;
            }

            utObj.lookupDetails.push({
                detailType: 'PART_NO',
                detailValue: products[partIndex].catalogKey.itemId,
            });

            resultsDetailsAddToInvoice = buildResultsDetailsObj(products[partIndex], 'ADD_TO_INVOICE', true);
            utObj.resultsDetails.push(resultsDetailsAddToInvoice);
            if (ppAvailability.itemMaster) {
                utObj.resultsDetails.push(buildResultsDetailsObj(products[partIndex], 'LOOKUP', true));
            }

            utObj.quoteProducts.push({
                vehicleId,
                baseVehicleId: null,
                partId: `${resultsDetailsAddToInvoice.brandCode}|${resultsDetailsAddToInvoice.catalogItemId}`,
                lookupType,
                parentPartTypeId: 0,
                uuid,
                stocking: resultsDetailsAddToInvoice.stocking,
                localQuantityOnHand: resultsDetailsAddToInvoice.localQOH,
            });
        }

        utObj.resultsSummary.push({
            partTypeId: 0,
            countProducts: products.length,
            countStocking: stockingTotals.countStocking,
            countNonStocking: stockingTotals.countNonStocking,
            countSpecialOrder: stockingTotals.countSpecialOrder,
            countOutOfStock: stockingTotals.countOutOfStock,

            // This countDisplayed value needs to be hardcoded for RWD
            countDisplayed: -1,
            duration: 0,
        });
        const utCalls = [];
        utCalls.push(logLookupDetails(assembleLookupDetails(utObj.lookupDetails, uuid.uuid)));
        utCalls.push(logLookupSummary(assembleLookupSummary(utObj.lookupSummary, uuid.uuid)));
        utCalls.push(logResultsSummary(assembleResultsSummary(utObj.resultsSummary, uuid.uuid)));
        utCalls.push(logResultsDetails(assembleResultsDetails(utObj.resultsDetails, false, uuid.uuid)));
        utCalls.push(addPartsToQuoteProducts(utObj.quoteProducts));
        Promise.all(utCalls).catch(() => {
            // Fail silently
        });
    });
});

export default {
    relatedPartTypesLookup,
    relatedProductsLookup,
    catalogLookup,
    addPrebuiltKitToQuote,
    addKitPartToQuote,
    plpMoreInformation,
    addPartToQuote,
    removePartFromQuote,
    viewPartFromQuote,
    quickAddPartsToQuote,
    startCatalogLookup,
};
